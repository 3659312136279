.goodsCarousel {
  position: relative;
}

/* ========== */
.goodsCarousel__prevArrow,
.goodsCarousel__nextArrow {
  top: 216px !important;
}

/* ========== */
.goodsCarousel__track__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

/* ========== */
.goodsCarousel__track__slide__imgWrapper {
  border-radius: 12px;
  position: relative;

  a {
    display: block;
    height: 432px;
    position: relative;
  }

  img {
    border-radius: 12px;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.goodsCarousel__track__slide__imgWrapper__favouritesIcon {
  margin: 16px 16px 0 0;
}

.goodsCarousel__track__slide__imgWrapper__label {
  position: absolute;
  left: 16px;
  bottom: 16px;
}

/* ========== */
.goodsCarousel__track__slide__productInfo {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  user-select: none;

  img {
    border-radius: 4px;
    height: 43px;
    margin: 0 auto;
    max-width: 131px;
    object-fit: contain;
    width: 100%;
  }
}

.goodsCarousel__track__slide__productInfo__name {
  color: var(--grey-700);
  margin-top: 8px;
  text-align: center;
}

/* ========== */
.goodsCarousel__track__slide__productInfo__price {
  margin-top: 16px;
}

.goodsCarousel__track__slide__productInfo__price__actual {
  color: var(--black);
  font-size: 26px;
  letter-spacing: -0.06em;
  line-height: 28px;
}

.goodsCarousel__track__slide__productInfo__price__oldPrice {
  color: var(--grey-600);
  margin: 6px 0;
  text-align: center;
  text-decoration: line-through;
}

.goodsCarousel__track__slide__productInfo__price__discount {
  color: var(--red-500);
}

/* ========== */
.goodsCarousel__track__slide__promoTxt {
  color: var(--grey-800);
  margin-top: 16px;
  max-width: 228px;
}

.goodsCarousel__track__slide__promoBtn {
  margin-top: 24px;
  justify-content: center;
  gap: 6px;

  svg {
    transform: rotate(180deg);

    path {
      fill: var(--blue-500);
    }
  }
}

@media screen and (max-width: 960px) {
  .goodsCarousel {
    overflow: hidden;
    width: calc(100% + 32px);
  }

  .goodsCarousel__prevArrow,
  .goodsCarousel__nextArrow {
    display: none !important;
  }

  /* ========== */
  .goodsCarousel__track {
    padding-right: 16px;
  }

  /* ========== */
  .goodsCarousel__track__slide {
    width: 288px;
  }

  .goodsCarousel__track__slide__imgWrapper {
    a {
      aspect-ratio: 2/3;
      display: block;
      width: 288px;
    }

    img {
      aspect-ratio: 2/3;
      position: static;
    }
  }

  /* ========== */
  .goodsCarousel .swiper-slide {
    width: 288px !important;
  }
}

@media screen and (max-width: 600px) {
  .goodsCarousel {
    overflow: hidden;
    width: calc(100% + 12px);
  }

  /* ========== */
  .goodsCarousel__track__slide__imgWrapper__label {
    bottom: 8px;
    left: 8px;
  }

  /* ========== */
  .goodsCarousel__track__slide__promoTxt {
    max-width: 200px;
  }
}

@media screen and (max-width: 305px) {
  .goodsCarousel .swiper-slide,
  .goodsCarousel__track__slide,
  .goodsCarousel__track__slide a {
    width: 256px !important;
  }
}
