.customModal {
  box-sizing: border-box;
}

/* ========== */
.customModal__wrapper {
  inset: 0;
  bottom: 0;
  inset-inline: 0;
  outline: 0;
  overflow: auto;
  position: fixed;
  text-align: center;
  top: 0;
  z-index: 1000;

  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
}

/* ========== */
.customModal__mask {
  background-color: transparent;
  bottom: 0;
  left: 0;
  min-width: 100vw;
  position: fixed;
  top: 0;
  transition: background-color 0.25s ease-in;
  z-index: 1000;
}

.customModal__mask_blackout {
  background-color: rgba(0, 0, 0, 0.45);
  transition: background-color 0.25s ease-in;
}

/* ========== */
.customModal__body {
  display: inline-block;
  max-width: 431px;
  opacity: 0;
  padding: 20px;
  text-align: start;
  transform: scale(0.1);
  transition: all 0.25s cubic-bezier(0.08, 0.82, 0.17, 1);
  vertical-align: middle;
  width: 100%;
}

.customModal__body_visible {
  transform: scale(1);
  opacity: 1;
}

.customModal__body_closed {
  transition: all 0.25s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.customModal__body_absolutePosition {
  position: absolute;
}

/* ========== */
.customModal__contentWrapper {
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.customModal__contentWrapper_defaultPaddings {
  padding: 40px 32px 32px;
}

/* ========== */
.customModal__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customModal__content__header {
  color: var(--black);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.customModal__content__header__subTitle {
  display: inline-block;
  color: var(--grey-700);
  margin-top: 12px;
}

.customModal__content__header__closeIcon {
  padding: 16px 16px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* ========== */
.customModal__content__body {
  margin-top: 24px;
  width: 100%;
}

.customModal__content__body__item {
  margin-top: 12px;
}

/* ========== */
.customModal__content__footer {
  margin-top: 16px;
}

@media screen and (max-width: 600px) {
  .customModal__body_fullscrean {
    height: 100%;
    min-width: 100%;
    padding: 0;

    .customModal__contentWrapper {
      border-radius: 0px;
      height: auto;
      min-height: 100%;
      padding: 76px 24px 24px;
    }
  }
}
