.customCheckbox {
  position: relative;
}

.customCheckbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* ========== */
.customCheckbox__input + label {
  align-items: flex-start;
  color: var(--grey-800);
  display: inline-flex;
  user-select: none;

  a {
    color: var(--black);
    margin-left: 4px;
    transition: 0.3s;

    &:hover {
      color: var(--blue-500);
    }
  }
}

.customCheckbox__input_normal + label {
  line-height: 24px;
}

/* ========== */
.customCheckbox__input + label::before {
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px solid var(--grey-300);
  content: "";
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  transition: 0.2s;
  margin-right: 8px;
}

.customCheckbox__input_normal + label::before {
  border-radius: 6px;
  height: 20px;
  width: 20px;
}

.customCheckbox__input_small + label::before {
  border-radius: 4px;
  height: 12px;
  width: 12px;
}

.customCheckbox__input_withoutText + label::before {
  margin-right: 0px;
}
/* ========== */
.customCheckbox__input:checked + label::before {
  border: 2px solid var(--blue-500) !important;
  background-color: var(--blue-500) !important;
}

.customCheckbox__input_normal:checked + label::before {
  background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M17.0658 7.65164L9.9947 14.7227C9.60418 15.1132 9.60418 15.7464 9.9947 16.1369C10.3852 16.5275 11.0184 16.5275 11.4089 16.1369L18.48 9.06586C18.8705 8.67533 18.8705 8.04217 18.48 7.65164C18.0895 7.26112 17.4563 7.26112 17.0658 7.65164Z' fill='white'/%3e%3cpath d='M5.75216 11.8943L9.9948 16.1369C10.3853 16.5275 11.0185 16.5275 11.409 16.1369C11.7995 15.7464 11.7995 15.1132 11.409 14.7227L7.16637 10.4801C6.77584 10.0896 6.14268 10.0896 5.75216 10.4801C5.36163 10.8706 5.36163 11.5038 5.75216 11.8943Z' fill='white'/%3e%3c/svg%3e");
}

.customCheckbox__input_small:checked + label::before {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.82367 6.47487L4.82373 6.47494L3.76307 7.5356L0.227539 4.00007L1.2882 2.93941L3.76301 5.41422L8.71274 0.464478L9.7734 1.52514L4.82367 6.47487Z' fill='white'/%3E%3C/svg%3E%0A");
}

.customCheckbox__input:checked + .blackLabel::before {
  background-color: var(--grey-800) !important;
  border-color: var(--grey-800) !important;
}

.customCheckbox__input:not(:disabled):checked:not(:focus):hover + label::before {
  border-color: var(--blue-500);
}

.customCheckbox__input:not(:disabled):checked:not(:focus):hover + .blackLabel::before {
  border-color: var(--grey-800);
}

.customCheckbox__input:not(:disabled):not(:checked) + label:hover::before {
  border-color: var(--blue-500);
}

.customCheckbox__input:not(:disabled):not(:checked) + .blackLabel:hover::before {
  border-color: var(--grey-800);
}

.customCheckbox__input:not(:disabled):active + label::before {
  background-color: hsl(212, 100%, 85%);
  border-color: hsl(212, 100%, 85%);
}

.customCheckbox__input:not(:disabled):active + .blackLabel::before {
  background-color: var(--grey-300);
  border-color: var(--grey-600);
}

.customCheckbox__input:focus + label::before {
  box-shadow: 0 0 0 1px hsla(211, 100%, 50%, 0.251);
}

.customCheckbox__input:focus + .blackLabel::before {
  box-shadow: 0 0 0 1px var(--grey-300);
}

.customCheckbox__input:focus:not(:checked) + label::before {
  border-color: hsl(211, 100%, 75%);
}

.customCheckbox__input:focus:not(:checked) + .blackLabel::before {
  border-color: var(--grey-300);
}

.customCheckbox__input:disabled + label::before {
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
  cursor: default;
  pointer-events: none;
}

.customCheckbox__input:disabled + label {
  color: var(--grey-600);
  cursor: default;
  pointer-events: none;
}

.customCheckbox__input:disabled:checked + label::before {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
