.favouritesHeart {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &:hover svg path {
    stroke: var(--blue-200-alfa);
  }

  svg path {
    transition: 0.3s;
    stroke: var(--grey-500);
    fill: rgba(255, 255, 255, 0.5);
  }
}
