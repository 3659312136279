.mobileAppQr {
  align-items: center;
  cursor: pointer;
  display: flex;

  &:hover .typography_tec_16_default {
    color: var(--black);
  }

  .typography_tec_16_default {
    color: var(--grey-800);
    display: block;
    line-height: 20px;
    transition: 0.3s;
    width: min-content;
  }

  img {
    border-radius: 16px;
    height: 68px;
    margin-left: 20px;
    width: 68px;
  }
}
