.customSelectHints {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 1px 6px rgba(72, 91, 159, 0.05), 0px 6px 24px rgba(72, 91, 159, 0.16);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  padding: 0;
  position: absolute;
  scrollbar-color: var(--white);
  top: 53px;
  width: 100%;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-600);
    height: 30%;
    transition: 0.2s;
    border-radius: 2px;

    &:hover {
      background-color: var(--grey-700);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white);
  }
}

.customSelectHints_shown {
  height: auto;
  opacity: 1;
  padding: 8px 0;
  transition: 0.2s;
  max-height: 290px;
}

.customSelectHints_withLabel {
  top: 77px;
}

.customSelectHints__item {
  align-items: center;
  color: var(--grey-800);
  cursor: pointer;
  display: flex;
  height: max-content;
  min-height: 40px;
  padding: 8px 0 8px 16px;
  transition: 0.3s;

  &:hover {
    background-color: var(--grey-100);
  }
}

.customSelectHints__item__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .typography_tec_13_medium {
    display: block;
    margin-top: 2px;
    color: var(--grey-600);
  }
}

.customSelectHints__item__content__checkIcon {
  margin-right: 8px;

  path {
    fill: var(--blue-500);
  }
}

@media screen and (max-height: 600px) {
  .customSelectHints_shown {
    max-height: 240px;
  }
}
