.customInput {
  position: relative;
}

/* ========== */
.customInput_error .customInput__main {
  border-color: var(--red-500);

  &:hover {
    border-color: var(--red-500);
  }

  &:focus-within {
    border-color: var(--red-500);
  }
}

.customInput_success .customInput__main {
  border-color: var(--green-500);

  &:hover {
    border-color: var(--green-500);
  }

  &:focus-within {
    border-color: var(--green-500);
  }
}

.customInput_disabled {
  pointer-events: none;

  .customInput__main .customInput__main__input {
    color: var(--grey-600);
    -webkit-text-fill-color: var(--grey-600);

    &::placeholder {
      color: var(--grey-600);
    }
  }
}

/* ========== */
.customInput__label {
  color: var(--grey-600);
  margin-bottom: 8px;
  display: block;
}

/* ========== */
.customInput__main {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  border: 2px solid var(--grey-300);
  display: flex;
  height: 48px;
  position: relative;
  transition: 0.2s;

  &:hover {
    border-color: var(--grey-600);
  }

  &:focus-within {
    border-color: var(--blue-500);
  }

  .phoneInput input {
    border-radius: 12px;
  }
}

/* =========== */
.customInput__main__input {
  background-color: var(--white);
  border-radius: 12px;
  border: none;
  color: var(--black);
  height: 100%;
  outline: none;
  padding: 0;
  transition: 0.2s;
  width: 100%;

  &:-webkit-autofill {
    box-shadow: inset 0 0 0 50px var(--white);
    -webkit-text-fill-color: var(--black);
  }

  &::placeholder {
    color: var(--grey-700);
  }
}

/* ========== */
.customInput__main__prefix,
.customInput__main__suffix {
  display: flex;
  align-items: center;
  height: 100%;
}

.customInput__main__prefix {
  margin-right: 3px;
  padding-left: 16px;
}

.customInput__main__suffix {
  margin-left: 3px;
  padding-right: 16px;
}

.customInput__main__phoneInputWithPadding {
  padding-right: 16px;
}

/* ========== */
.customInput__message {
  color: var(--grey-600);
  display: block;
  margin-top: 8px;
}

.customInput__message_error {
  color: var(--red-500);
}

/* ========== */
.customInput__disableMask {
  background-color: hsla(0, 0%, 0%, 0.1);
  border-radius: 11px;
  height: 100%;
  left: 0px;
  position: absolute;
  width: 100%;
  z-index: 2;
}
