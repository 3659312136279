.customPlacemark-YM,
.customClaster-YM,
.customClaster-YM_small {
  width: 18px;
  height: 18px;
  background-color: var(--blue-500);
  border-radius: 18px;
  border: 3px solid var(--white);
}

.customClaster-YM,
.customClaster-YM_small {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  p {
    color: var(--white) !important;
  }
}

.customClaster-YM_small {
  width: 44px;
  height: 44px;
  border-radius: 44px;
}
