.customPopoverTrigger {
  position: relative;
}

.customPopover {
  cursor: auto;
  opacity: 0;
  display: none;
  overflow: visible;
  position: absolute;
  transform-origin: var(--arrow-x) var(--arrow-y);
  transform: scale(0.5);
  transition: all 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  visibility: hidden;
  z-index: 1000;
  animation-delay: attr(data-delay);
}

.customPopover_active {
  animation: isActive 0.2s forwards;

  @keyframes isActive {
    to {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
  }
}

.customPopover__contentWrapper {
  width: max-content;
}

.customPopover__content {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 15px 16px;
}

.customPopover__arrow {
  display: block;
  height: 16px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 16px;
  z-index: 1;

  &::before {
    background: var(--background-color);
    bottom: 0;
    content: "";
    height: 8px;
    inset-inline-start: 0;
    position: absolute;
    width: 16px;
    clip-path: path(
      "M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z"
    );
  }
}

// TOP
.customPopover_top .customPopover__arrow {
  bottom: 0.5px;
  left: var(--arrow-x);
  transform: translateY(100%) translateX(-50%) rotate(180deg);
}

.customPopover_topLeft .customPopover__arrow {
  bottom: 0.5px;
  left: var(--arrow-x);
  transform: translateY(100%) translateX(-50%) rotate(180deg);
}

.customPopover_topRight .customPopover__arrow {
  bottom: 0.5px;
  left: var(--arrow-x);
  transform: translateY(100%) translateX(-50%) rotate(180deg);
}

// BOTTOM
.customPopover_bottom .customPopover__arrow {
  left: var(--arrow-x);
  top: 0.5px;
  transform: translateY(-100%) translateX(-50%);
}

.customPopover_bottomLeft .customPopover__arrow {
  left: var(--arrow-x);
  top: 0.5px;
  transform: translateY(-100%) translateX(-50%);
}

.customPopover_bottomRight .customPopover__arrow {
  left: var(--arrow-x);
  top: 0.5px;
  transform: translateY(-100%) translateX(-50%);
}

// LEFT
.customPopover_left .customPopover__arrow {
  right: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(100%) rotate(90deg);
}

.customPopover_leftTop .customPopover__arrow {
  right: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(100%) rotate(90deg);
}

.customPopover_leftBottom .customPopover__arrow {
  right: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(100%) rotate(90deg);
}

// RIGHT
.customPopover_right .customPopover__arrow {
  left: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(-100%) rotate(270deg);
}

.customPopover_rightTop .customPopover__arrow {
  left: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(-100%) rotate(270deg);
}

.customPopover_rightBottom .customPopover__arrow {
  left: 0.5px;
  top: var(--arrow-y);
  transform: translateY(-50%) translateX(-100%) rotate(270deg);
}
