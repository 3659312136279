.loader__wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  .loader__content {
    animation: preloader-rotate 2s infinite linear;
    height: 100%;
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    transition: 0.3s;
    width: 100%;
  }
}

.loader__wrapper_page {
  min-height: 150px;
}

.loader__wrapper_disabled .loader__content {
  animation: preloader-rotate_disabled 2s infinite linear !important;
  height: auto !important;
  opacity: 0.5 !important;
  width: auto !important;
}

@keyframes preloader-rotate {
  0% {
    fill: var(--grey-700);
    transform: rotate(0deg);
  }

  25% {
    fill: var(--blue-500);
  }

  100% {
    fill: var(--grey-700);
    transform: rotate(360deg);
  }
}

@keyframes preloader-rotate_disabled {
  33% {
    fill: var(--grey-900);
  }

  66% {
    fill: var(--grey-600);
  }

  100% {
    transform: rotate(360deg);
  }
}
