/* ========== */
.desktopFiltersSkeleton {
  margin-top: 16px;
}

.desktopFiltersSkeleton__item {
  border-bottom: 1px solid var(--grey-300);
  padding-bottom: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:nth-child(2) .desktopFiltersSkeleton__item__title {
    max-width: 155px;
  }

  &:nth-child(3) .desktopFiltersSkeleton__item__title {
    max-width: 94px;
  }
}

.desktopFiltersSkeleton__item__title {
  height: 16px;
  max-width: 104px;
  border-radius: 3px;
}

.desktopFiltersSkeleton__item__list {
  margin-top: 16px;
}

.desktopFiltersSkeleton__item__list__filter {
  display: flex;

  &:not(:first-child) {
    margin-top: 14px;
  }

  &:nth-child(1) {
    // desktopFiltersSkeleton__item__list__filter__text
    width: 166px;
  }

  &:nth-child(2) {
    width: 141px;
  }

  &:nth-child(3) {
    width: 135px;
  }

  &:nth-child(4) {
    width: 187px;
  }

  &:nth-child(5) {
    width: 94px;
  }

  &:nth-child(6) {
    width: 125px;
  }

  &:nth-child(8) {
    width: 85px;
  }

  &:nth-child(9) {
    width: 177px;
  }

  &:nth-child(10) {
    width: 156px;
  }

  &:nth-child(11) {
    width: 83px;
  }

  &:nth-child(12) {
    width: 94px;
  }
}

.desktopFiltersSkeleton__item__list__filter__checkbox {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 4px;
}

.desktopFiltersSkeleton__item__list__filter__text {
  height: 16px;
  margin-left: 8px;
  border-radius: 4px;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .desktopFiltersSkeleton {
    display: none;
  }
}
