.notifications__wrapper {
  position: absolute;
  right: 0px;
  top: -52px;
  width: 388px;
  z-index: 4;

  &[data-is_header_shown="false"] {
    top: 20px;
  }
}

.notifications__category {
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0px 1px 6px rgba(72, 91, 159, 0.1), 0px 20px 40px rgba(72, 91, 159, 0.16);
  padding: 16px;
  position: relative;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &[data-category="discountIncrease"] {
    background-color: #1d2439;

    .notifications__category__item__icon {
      display: none;
    }
  }
}

.notifications__category__item {
  align-items: flex-start;
  display: flex;
  gap: 16px;

  &:not(:nth-child(2)) {
    /* bcs first itm is Close icon */
    margin-top: 16px;
  }

  svg {
    min-width: 40px;
  }
}

.notifications__category__item__icon {
  align-items: center;
  background-color: var(--grey-100);
  border-radius: 36px;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
}

.notifications__category__item__title {
  color: var(--black);

  &[data-is_warning="true"] {
    color: var(--red-500);
  }
}

.notifications__category__item__text {
  width: 100%;

  span {
    display: block;
    margin-top: 2px;
  }
}

.notifications__category__item__text__discountIncrease {
  color: var(--white);
  width: 100%;

  span {
    margin-top: 0;
  }
}

.notifications__category__item__text__discountIncrease__description {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  gap: 8px;

  button {
    background-color: transparent;
  }
}

@media screen and (max-width: 960px) {
  .notifications__wrapper {
    right: -20px;
    top: 8px;
  }
}

@media screen and (max-width: 600px) {
  .notifications__wrapper {
    right: 0px;
    width: 100%;
  }

  .notifications__category__closeIcon {
    padding: 12px 12px 0 0;
  }
}

@media screen and (max-width: 319px) {
  .notifications__category {
    padding: 12px;
  }

  .notifications__category__item__text {
    margin-left: 8px;
  }

  .notifications__category__closeIcon {
    padding: 6px 6px 0 0;
  }
}
