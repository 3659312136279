.customLink,
.customLink_grey {
  color: var(--blue-500);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--blue-600);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.customLink_grey {
  color: var(--grey-700);

  &:hover {
    color: var(--grey-800);
  }
}

.customLink_dotted {
  border-bottom: 1px dotted var(--blue-500);

  &:hover {
    border-color: var(--blue-600);
  }
}
