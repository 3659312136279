.popularBrands__title {
  text-align: center;
  text-transform: uppercase;
}

.popularBrands__grid {
  margin-top: 48px;
}

@media screen and (max-width: 600px) {
  .popularBrands__grid {
    margin-top: 32px;
  }
}
