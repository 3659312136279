.authModal .customModal__content__body {
  margin: 0;
}

/* ========== */
.authModal__header__controls__arrowIcon {
  cursor: pointer;
  height: 40px;
  left: 0;
  padding: 16px 0 0 16px;
  position: absolute;
  top: 0;
  width: 40px;

  path {
    fill: var(--grey-600);
    transition: 0.2s;
  }

  &:hover path {
    fill: var(--grey-700);
  }
}

/* ========== */
.authModal__loginScreen__form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.authModal__loginScreen__form__codeSubmitBtn {
  align-items: center;
  color: var(--grey-500);
  cursor: default;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 56px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

/* ========== */
.authModal__policyScreen__content {
  display: flex;
  margin-top: 24px;
}

.authModal__policyScreen__content__icon {
  min-width: 24px;
}

.authModal__policyScreen__content__info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  .typography_h3 {
    color: var(--black);
  }

  .typography_captionTxt {
    color: var(--blue-500);
    margin-top: 4px;
  }
}

/* ========== */
.authModal__codeScreen__phoneNumber {
  color: var(--black);
  display: block;
  margin-top: 12px;
}

/* ========== */
.authModal__successRegister__agreements_sale {
  margin-top: 24px;

  label {
    .typography_captionTxt {
      color: var(--grey-700);
    }

    &::before {
      margin-top: 3px;
    }
  }
}

@media screen and (max-width: 600px) {
  .authModal {
    .customModal__content {
      margin: 0 auto;
      max-width: 327px;
    }
  }

  .authModal__codeScreen p {
    display: block !important;
  }
}

@media screen and (max-width: 319px) {
  .verificationCode__item .customInput__main__input {
    padding: 8px !important;
  }
}
