.mobileNavigation {
  background-color: var(--white);
  bottom: 0;
  box-shadow: 0px -1px 4px rgba(199, 211, 244, 0.51);
  display: none;
  grid-template-columns: repeat(5, 1fr);
  left: 0px;
  padding: 9px 0;
  position: fixed;
  width: 100%;
  z-index: 3;

  .active {
    svg path {
      fill: var(--blue-500);
    }

    .mobileNavigation__link__icon__svg_favouritesPage path {
      fill: var(--white);
      stroke: var(--blue-500);
    }

    .mobileNavigation__link__txt {
      color: var(--blue-500);
    }
  }
}

.mobileNavigation__link {
  align-items: center;
  color: var(--grey-850);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    svg path {
      fill: var(--blue-500);
    }

    .mobileNavigation__link__icon__svg_favouritesPage path {
      fill: var(--white);
      stroke: var(--blue-500);
    }

    .mobileNavigation__link__txt {
      color: var(--blue-500);
    }
  }
}

.mobileNavigation__link__svg path,
.mobileNavigation__link__icon__svg path {
  fill: var(--grey-800);
  transition: 0.3s;
}

.mobileNavigation__link__icon {
  height: 24px;
  position: relative;
}

.mobileNavigation__link__icon__svg_favouritesPage path {
  fill: var(--white);
  stroke: var(--grey-800);
}

.mobileNavigation__link__icon__counter {
  left: 13px;
  top: 5px;
}

.mobileNavigation__link__txt {
  color: var(--grey-800);
  margin-top: 2px;
  transition: 0.3s;
}

@media screen and (max-width: 960px) {
  .mobileNavigation {
    display: grid;
  }
}
