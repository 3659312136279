.customBtn {
  border-radius: 12px;
  border: 2px solid var(--grey-300);
  color: var(--grey-800);
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-feature-settings: "ss01", "cv01";
  font-size: 14px;
  font-style: normal;
  font-variation-settings: "wght" 460;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 24px;
  padding: 8px 16px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    border-color: var(--grey-500);
  }

  &:active {
    background-color: var(--grey-100);
    border-color: var(--grey-800);
  }

  &:disabled {
    background-color: var(--grey-100);
    color: var(--grey-600);
    cursor: default;
    pointer-events: none;
  }

  svg {
    display: block;
  }
}

.customBtn_large {
  border-radius: 16px;
  font-size: 16px;
  padding: 14px 22px;

  &.customBtn_withPrefix {
    padding-left: 14px;

    svg,
    img {
      margin-right: 8px;
    }
  }

  &.customBtn_withSuffix {
    padding-right: 14px;

    svg,
    img {
      margin-left: 8px;
    }
  }
}

.customBtn_small {
  border-radius: 10px;
  padding: 4px 14px;
}

/* ========== */
.customBtn_outline {
  border: 2px solid var(--blue-200-alfa);
  color: var(--blue-500);

  &:hover {
    border-color: var(--blue-500);
    color: var(--blue-500);
  }

  &:active {
    background-color: var(--blue-100-alfa);
    border-color: var(--blue-500);
  }

  &:disabled {
    background-color: var(--white);
    border-color: var(--grey-300);
    color: var(--grey-300);
  }
}

/* ========== */
.customBtn_contained {
  background-color: var(--blue-500);
  border: 2px solid var(--blue-500);
  color: var(--white);

  &:hover {
    background-color: var(--blue-400);
    border-color: var(--blue-400);
    color: var(--white);
  }

  &:active {
    background-color: var(--blue-600);
    border-color: var(--blue-600);
  }

  &:disabled {
    background-color: var(--grey-300);
    border-color: var(--grey-300);
    color: var(--white);
  }

  svg path {
    fill: var(--white);
  }
}

/* ========== */
.customBtn_containedGrey {
  background-color: var(--grey-100);
  border-color: var(--grey-100);
  color: var(--grey-800);

  &:hover {
    background-color: var(--grey-200);
    border-color: var(--grey-200);
  }

  &:active {
    background-color: var(--grey-300);
    border-color: var(--grey-300);
  }

  &:disabled {
    background-color: var(--grey-100);
    border-color: var(--grey-100);
    color: var(--grey-500);
  }
}

/* ========== */
.customBtn_withPrefix {
  padding-left: 8px;

  svg,
  img {
    margin-right: 6px;
  }
}

/* ========== */
.customBtn_withSuffix {
  padding-right: 8px;

  svg,
  img {
    margin-left: 6px;
  }
}

/* ========== */
.customBtn_icon {
  padding: 8px;

  * {
    transition: 0.3s;
  }

  &.customBtn_outline {
    svg path {
      stroke: var(--blue-500);
    }

    &[data-type="favourites_active"] svg path {
      fill: var(--blue-500);
    }

    &[data-type="favourites"]:active svg path {
      fill: rgba(255, 255, 255, 0.5);
    }

    &:disabled {
      background-color: var(--white);
      border-color: var(--grey-300);

      svg path {
        stroke: var(--grey-400);
      }
    }
  }
}

.customBtn_iconLarge {
  padding: 14px;
}

.customBtn_iconSmall {
  padding: 4px;
  border-radius: 10px;
}
