.increasedDiscountTimer {
  align-items: center;
  background: linear-gradient(86deg, #ef3f54 4.07%, #dc3063 96.77%);
  border-radius: 24px;
  color: var(--white);
  display: flex;
  gap: 6px;
  padding-right: 10px;
  max-width: max-content;
}

.increasedDiscountTimer__label {
  display: block;
  background: linear-gradient(
    60deg,
    #2cfb70 7.78%,
    #13e8bf 27.83%,
    #36ddec 44.53%,
    #36c4ff 60.4%,
    #3da4f4 75.44%,
    #4c6cee 91.31%
  );
  border-radius: 24px;
  color: var(--black);
  padding: 1px 6px;
}

@media screen and (max-width: 600px) {
  .increasedDiscountTimer__label {
    line-height: 24px;
    padding: 0 6px;
  }
}
