.customLightScrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-300);
    border-radius: 2px;

    &:hover {
      background-color: var(--grey-500);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
  }
}
