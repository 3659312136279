.catalogSkeleton {
  margin-top: 24px;
}

/* ========== */
.catalogSkeleton__breadcrumbs {
  display: flex;
  justify-content: center;
}

.catalogSkeleton__breadcrumbs__item {
  border-radius: 3px;
  height: 16px;

  &:first-child {
    width: 60px;
  }

  &:last-child {
    width: 80px;
  }
}

.catalogSkeleton__breadcrumbs__devider {
  color: var(--grey-700);
  display: block;
  font-size: 15px;
  letter-spacing: -0.03em;
  line-height: 16px;
}

/* ========== */
.catalogSkeleton__title {
  border-radius: 12px;
  height: 40px;
  margin: 12px auto 0;
  width: 200px;
}

/* ========== */
.catalogSkeleton__mobileFiltersBtn {
  border-radius: 12px;
  display: none;
  height: 44px;
  margin: 24px auto 0;
  width: 231px;
}

/* ========== */
.catalogSkeleton__controlsPanel {
  border-bottom: 1px solid var(--grey-300);
  display: flex;
  height: 25px;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 8px;
}

.catalogSkeleton__controlsPanel__item {
  border-radius: 4px;
  height: 16px;

  &:first-child {
    width: 70px;
  }

  &:nth-child(2) {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100px;
  }

  &:last-child {
    width: 126px;
  }
}

/* ========== */
.catalogSkeleton__mainContent {
  display: grid;
  gap: 16px;
  grid-template-columns: 208px 1fr;
}

// .catalogSkeleton__mainContent__filters {
//   padding-right: 7px;
// }

/* ========== */
.catalogSkeleton__mainContent__products {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  width: 100%;
}

/* ========== */
.catalogSkeleton__mainContent__products__saleBanner {
  border-radius: 12px;
  min-height: 60px;
  width: 100%;
}

/* ========== */
.catalogSkeleton__mainContent__products__grid {
  display: grid;
  gap: 20px 16px;
  grid-template-columns: repeat(7, 1fr);
}

@media screen and (max-width: 1919px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1679px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1439px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1179px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 960px) {
  .catalogSkeleton__mainContent {
    gap: 0;
    grid-template-columns: 1fr;
  }

  .catalogSkeleton__controlsPanel__item {
    &:nth-child(2) {
      left: auto;
      right: 0;
      transform: translateX(0);
    }

    &:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .catalogSkeleton__title {
    border-radius: 6px;
    height: 24px;
  }

  .catalogSkeleton__mobileFiltersBtn {
    display: block;
  }

  .catalogSkeleton__controlsPanel__item {
    display: none;
  }

  .catalogSkeleton__mainContent__products__saleBanner {
    border-radius: 16px;
  }

  .catalogSkeleton__mainContent__products {
    margin-top: 16px;
  }

  .catalogSkeleton__mainContent__products__grid {
    grid-column-gap: 6px;
  }
}

@media screen and (max-width: 359px) {
  .catalogSkeleton__mainContent__products__grid {
    grid-template-columns: 1fr;
  }
}
