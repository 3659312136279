.goodsCarousel_skeleton__title {
  border-radius: 8px;
  height: 40px;
  margin: 100px auto 56px;
  width: 200px;
}

/* =========== */
.goodsCarousel__track__slide_skeleton {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.goodsCarousel__track__slide_skeleton__preview {
  border-radius: 12px;
  height: 432px;
  width: 100%;
}

.goodsCarousel__track__slide_skeleton__brand {
  border-radius: 4px;
  height: 43px;
  margin-top: 16px;
  width: 131px;
}

.goodsCarousel__track__slide_skeleton__name {
  border-radius: 4px;
  height: 20px;
  margin-top: 8px;
  width: 80%;
}

.goodsCarousel__track__slide_skeleton__price {
  border-radius: 4px;
  height: 56px;
  margin-top: 16px;
  width: 60%;
}

@media screen and (max-width: 960px) {
  .goodsCarousel_skeleton__title {
    margin: 64px auto 32px;
  }

  /* ========== */
  .goodsCarousel_skeleton .swiper-slide {
    width: 288px !important;
  }
}

@media screen and (max-width: 600px) {
  .goodsCarousel_skeleton__title {
    height: 24px;
    margin: 49px auto 32px;
  }
}
