.closeIcon-wrapper {
  cursor: pointer;

  &:hover .closeIcon__item {
    background-color: var(--grey-700);
  }
}

.closeIcon-wrapper_defaultClosePosition {
  padding: 16px 16px 0 0;
  position: absolute;
  right: 0;
  top: 0;

  &[data-size="small"] {
    padding: 8px 8px 0 0;
  }
}

.closeIcon {
  position: relative;
}

/* ========== */
.closeIcon_withBackground {
  background-color: var(--grey-100);
  border-radius: 4px;
}

.closeIcon_regular.closeIcon_withBackground,
.closeIcon_large.closeIcon_withBackground {
  background-color: var(--white);
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background-color: var(--grey-100);
  }
}

.closeIcon__item {
  background-color: var(--grey-600);
  border-radius: 1px;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  transition: 0.2s;
}

/* ========== */
.closeIcon_regular {
  height: 24px;
  width: 24px;
}

.closeIcon__item_regular,
.closeIcon__item_large,
.closeIcon__item_small {
  width: 18px;

  &:first-child {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:last-child {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

/* ========== */
.closeIcon_large {
  height: 28px;
  width: 28px;
}

/* ========== */
.closeIcon_small {
  height: 16px;
  width: 16px;
}

.closeIcon__item_small {
  width: 13px;
}
