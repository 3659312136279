.storeContactInfo {
  display: flex;
  gap: 24px;
}

.storeContactInfo__phone {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .typography_tec_13_medium {
    margin-top: 4px;
    color: var(--grey-700);
  }
}

.storeContactInfo__social {
  display: flex;
  column-gap: 8px;
}

@media screen and (max-width: 600px) {
  .storeContactInfo__phone {
    align-items: center;
  }
}
