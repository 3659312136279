.carouselNavArrow {
  align-items: center;
  background-color: var(--white);
  border-radius: 48px;
  box-shadow: 0px 1px 6px hsla(227, 38%, 45%, 0.05), 0px 6px 24px hsla(227, 38%, 45%, 0.16);
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;

  &:hover {
    box-shadow: 0px 1px 6px hsla(227, 38%, 45%, 0.2), 0px 6px 24px hsla(227, 38%, 45%, 0.16);
  }

  &:disabled {
    opacity: 0;
    cursor: default;
    height: 0px;
    width: 0px;

    svg {
      opacity: 0;
    }
  }

  &[aria-label="carouselNavArrow_prev"],
  &[aria-label="carouselNavArrow_next"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &[aria-label="carouselNavArrow_prev"] {
    left: -24px;
  }

  &[aria-label="carouselNavArrow_next"] {
    right: -24px;
  }
}
