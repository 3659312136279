/* common */
.typography_title,
.typography_h1,
.typography_h2,
.typography_h3,
.typography_h4,
.typography_leadTxt,
.typography_bodyTxt,
.typography_captionTxt,
.typography_tec_title,
.typography_tec_notification,
.typography_tec_15_menu,
.typography_tec_13_semiBold,
.typography_tec_13_semiBoldThin,
.typography_tec_14_semiBold,
.typography_tec_14_medium,
.typography_tec_14_body,
.typography_tec_16_body,
.typography_tec_16_default,
.typography_tec_13_medium,
.typography_tec_10_medium,
.typography_tec_16_medium,
.typography_tec_12_medium,
.typography_tec_buttonM,
.typography_tec_buttonL {
  font-family: Inter, sans-serif;
  font-feature-settings: "salt" on;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

/* weight */
.typography_h1,
.typography_h2,
.typography_h3,
.typography_h4,
.typography_tec_15_menu,
.typography_tec_13_semiBold,
.typography_tec_13_semiBoldThin {
  font-variation-settings: "wght" 650;
}

.typography_tec_10_medium,
.typography_tec_12_medium,
.typography_tec_16_medium {
  font-variation-settings: "wght" 500;
}

.typography_leadTxt,
.typography_bodyTxt,
.typography_captionTxt,
.typography_tec_notification,
.typography_tec_16_default,
.typography_tec_13_medium,
.typography_tec_buttonM,
.typography_tec_buttonL {
  font-variation-settings: "wght" 460;
}

/* ========== */
.typography_title {
  font-size: 28px;
  font-variation-settings: "wght" 700;
  letter-spacing: 0.03em;
  line-height: 40px;
}

.typography_h1 {
  font-size: 26px;
  letter-spacing: -0.03em;
  line-height: 32px;
}

.typography_h2 {
  font-size: 22px;
  letter-spacing: -0.03em;
  line-height: 26px;
}

.typography_h3 {
  font-size: 18px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

.typography_h4 {
  font-size: 16px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

.typography_leadTxt {
  font-size: 18px;
  letter-spacing: -0.03em;
  line-height: 26px;
}

.typography_bodyTxt {
  font-size: 16px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

.typography_captionTxt {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 22px;
}

/* ========== */
.typography_tec_title {
  font-size: 24px;
  font-variation-settings: "wght" 600;
  letter-spacing: -0.03em;
  line-height: 32px;
}

.typography_tec_buttonM,
.typography_tec_buttonL {
  font-size: 14px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

.typography_tec_buttonL {
  font-size: 16px;
}

.typography_tec_notification {
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 18px;
}

.typography_tec_16_medium,
.typography_tec_16_body {
  font-size: 16px;
  letter-spacing: -0.03em;
  line-height: 24px;
}

.typography_tec_14_body {
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 22px;
}

.typography_tec_14_semiBold {
  font-size: 14px;
  font-variation-settings: "wght" 700;
  letter-spacing: 0.03em;
  line-height: 18px;
}

.typography_tec_12_medium {
  font-size: 12px;
  letter-spacing: -0.03em;
  line-height: 18px;
}

.typography_tec_10_medium {
  font-size: 10px;
  letter-spacing: -0.02em;
  line-height: 14px;
}

/* Legacy tec styles */
.typography_tec_16_default {
  font-size: 16px;
  letter-spacing: -0.03em;
  line-height: 16px;
}

.typography_tec_15_menu {
  font-size: 15px;
  letter-spacing: 0.03em;
  line-height: 16px;
}

.typography_tec_13_semiBold {
  font-size: 13px;
  letter-spacing: 0.03em;
  line-height: 16px;
}

.typography_tec_13_semiBoldThin {
  font-size: 13px;
  letter-spacing: -0.03em;
  line-height: 16px;
}

.typography_tec_13_medium {
  font-size: 13px;
  letter-spacing: -0.03em;
  line-height: 16px;
}

@media screen and (max-width: 600px) {
  .typography_title {
    font-size: 20px;
    font-variation-settings: "wght" 750;
    line-height: 24px;
  }

  .typography_h1 {
    font-size: 22px;
    line-height: 28px;
  }

  .typography_h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .typography_h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .typography_h4 {
    font-size: 14px;
    line-height: 22px;
  }

  .typography_leadTxt {
    font-size: 16px;
    line-height: 24px;
  }

  .typography_bodyTxt {
    font-size: 14px;
    line-height: 22px;
  }

  .typography_captionTxt {
    font-size: 12px;
    line-height: 20px;
  }
}
