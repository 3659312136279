.socialIcon {
  border-radius: 16px;
  width: max-content;

  &:hover {
    .socialIcon_vk path:first-child {
      fill: #07f;
    }

    .socialIcon_ok path {
      fill: #f78d1e;
    }

    .socialIcon_tg path {
      fill: #27a7ec;
    }
  }
}
