.notificationCounter {
  background-color: var(--blue-500);
  border-radius: 9px;
  color: var(--white);
  display: none;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-variation-settings: "wght" 550;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 10px;
  min-height: 17px;
  min-width: 17px;
  padding: 3px 4px;
  position: absolute;
  text-align: center;
}
