.mobileAppQrModal__body__switch .switchPanel__item {
  min-width: inherit;
  width: 100%;
}

.mobileAppQrModal__body__qr {
  height: 192px;
  margin: 24px auto 0;
  width: 192px;
  display: block;
}
