.personalAreaLayout {
  padding: 40px 0 86px;
}

.personalAreaLayout_standartSize,
.personalAreaLayout_largeSize {
  margin: 0 auto;
  max-width: 880px;
}

.personalAreaLayout_largeSize {
  max-width: 1106px;
}

@media screen and (max-width: 960px) {
  .personalAreaLayout {
    background-color: var(--grey-100);
    height: 100%;
    padding-top: 0px;
    transform: translate(-32px);
    width: calc(100% + 64px);
    padding-bottom: 12px;
  }

  .personalAreaLayout_standartSize,
  .personalAreaLayout_largeSize {
    max-width: none;
  }
}

@media screen and (max-width: 600px) {
  .personalAreaLayout {
    transform: translate(-12px);
    width: calc(100% + 24px);
  }
}
