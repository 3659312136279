.customCollapse__panel {
  display: block;
  background: transparent;
}

/* ========== */
.customCollapse__panel__header {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  line-height: 1;
  padding: 16px 8px 16px 0;
  position: relative;
  width: 100%;
}

.customCollapse__panel__header__icon svg {
  transition: transform 0.2s;
}

.customCollapse__panel__header__icon_end {
  order: 2;
}

.customCollapse__panel__header__icon_start {
  order: 0;
}

.customCollapse__panel__header__text {
  order: 1;
  width: 100%;
}

/* ========== */
.customCollapse__panel__contentWrapper {
  background-color: transparent;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.15s cubic-bezier(0, 1, 0, 1);
}

.customCollapse__panel__contentWrapper_active {
  max-height: 300vh;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
