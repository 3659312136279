.brandsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  &[data-is_flex_gap_supported="true"] {
    .brandsGrid__item,
    .brandsGrid__item_skeleton {
      margin: 0;
    }
  }
}

.brandsGrid__item,
.brandsGrid__item_skeleton {
  margin: 0 0 16px 16px;
}

.brandsGrid__item {
  align-items: center;
  aspect-ratio: 18 / 9;
  background-color: var(--grey-50);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  max-width: 208px;
  padding: 30px 38px;
  transition: 0.3s;
  width: 100%;

  &:hover {
    background-color: var(--grey-100);
  }
}

.brandsGrid__item_skeleton {
  aspect-ratio: 18 / 9;
  border-radius: 6px;
  max-width: 208px;
  min-height: 103px;
  width: 100%;
}

.brandsGrid__item__logo {
  width: 100%;
}

@media screen and (max-width: 960px) {
  .brandsGrid {
    gap: 8px;
  }

  .brandsGrid__item,
  .brandsGrid__item_skeleton {
    max-width: calc(25% - 8px);
    margin: 0 0 8px 8px;
  }

  .brandsGrid__item_skeleton {
    min-height: 86px;
  }
}

@media screen and (max-width: 768px) {
  .brandsGrid__item,
  .brandsGrid__item_skeleton {
    max-width: calc(33% - 8px);
  }
}

@media screen and (max-width: 600px) {
  .brandsGrid__item,
  .brandsGrid__item_skeleton {
    max-width: calc(50% - 8px);
  }
}

@media screen and (max-width: 374px) {
  .brandsGrid__item {
    padding: 30px 20px;
  }
}
