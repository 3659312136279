.pseudoSearchInput {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  border: 2px solid var(--grey-300);
  cursor: pointer;
  display: flex;
  padding: 8px 10px;
  position: relative;
  transition: 0.2s;

  &:hover {
    border-color: var(--blue-500);
  }
}

.pseudoSearchInput_disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

/* ========== */
.pseudoSearchInput__placeholder {
  color: var(--grey-600);
}

/* ========== */
.pseudoSearchInput__searchBtn {
  align-items: center;
  background-color: var(--blue-500);
  border-radius: 0 12px 12px 0;
  color: var(--white);
  display: flex;
  height: 44px;
  justify-content: center;
  position: absolute;
  right: -2px;
  text-align: center;
  width: 54px;
}

.pseudoSearchInput__searchBtn__icon path {
  fill: var(--white);
}
