.skeletonLoading {
  background-color: var(--grey-400);
  animation: skeletonOpacity 1.2s infinite alternate;
}

@keyframes skeletonOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
