.customDrawer {
  inset: 0;
  pointer-events: none;
  position: fixed;
  z-index: 1000;
}

.customDrawer_left > .customDrawer__content-wrapper {
  bottom: 0;
  left: 0;
  top: 0;
}

.customDrawer_right > .customDrawer__content-wrapper {
  bottom: 0;
  right: 0;
  top: 0;
}

.customDrawer_top > .customDrawer__content-wrapper {
  inset-inline: 0;
  top: 0;
}

.customDrawer_bottom > .customDrawer__content-wrapper {
  bottom: 0;
  inset-inline: 0;
}

/* ========== */
.customDrawer__mask {
  background: rgba(0, 0, 0, 0.45);
  inset: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute;
  transition: opacity 0.3s;
  z-index: 1000;
}

.customDrawer__mask.customDrawer__mask_in.customDrawer__mask_visible {
  opacity: 1;
}

/* ========== */
.customDrawer__content-wrapper {
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12),
    -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  position: absolute;
  transition: transform 0.3s;
  z-index: 1000;

  &-right {
    transform: translate(100%);
  }

  &-left {
    transform: translate(-100%);
  }

  &-bottom {
    transform: translateY(100%);
  }

  &-top {
    transform: translateY(-100%);
  }
}

.customDrawer__content-wrapper.customDrawer__content-wrapper_in.customDrawer__content-wrapper_visible {
  transform: translate(0);
}

/* ========== */
.customDrawer__content {
  background: #fff;
  height: 100%;
  overflow: auto;
  pointer-events: auto;
  width: 100%;
}

/* ========== */
.customDrawer__content__main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

/* ========== */
.customDrawer__content__main__header {
  align-items: center;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  flex: 0;
  font-size: 50px;
  line-height: 3;
  padding: 16px 24px;
}

.customDrawer__content__main__header__title-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.customDrawer__content__main__header__title {
  color: rgba(0, 0, 0, 0.88);
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

/* ========== */
.customDrawer__content__main__body {
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: auto;
  padding: 24px;
}

/* ========== */
.customDrawer__content__main__footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  flex-shrink: 0;
  padding: 16px 24px;
}
