.managerWidget {
  position: fixed;
  left: -150px;
  top: 79px;
  width: 80px;
  height: 80px;
  z-index: 5;
  cursor: pointer;
  transition: 0.3s;
  display: flex;

  &:hover .managerWidget__content {
    box-shadow: 0px 0px 5px hsl(0, 0%, 40%);
  }
}

.managerWidget_showed {
  left: 30px;
}

.managerWidget_hidden {
  width: 50px;
  height: 50px;
  top: 94px;
  left: -23px;
  opacity: 0.7;

  &:hover {
    left: -20px;
    opacity: 1;
  }
}

.managerWidget__content {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 3px var(--white);
  background-color: var(--blue-500);
  margin: auto;
  display: flex;
  transition: 0.2s;

  svg {
    margin: auto;
    fill: var(--white);
  }
}

.managerWidget__icon path {
  fill: var(--white);
}

@media screen and (max-width: 960px) {
  .managerWidget {
    top: 50px;
  }

  .managerWidget_hidden {
    top: 65px;
  }
}
