.layoutWithSidebar {
  display: flex;
  justify-content: space-between;
  margin: 48px auto 96px;
  max-width: 1104px;
  position: relative;
  width: 100%;
}

.layoutWithSidebar__main {
  max-width: 676px;
  width: 100%;
  padding-right: 20px;
}

@media screen and (max-width: 960px) {
  .layoutWithSidebar__main {
    max-width: 100%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  .layoutWithSidebar {
    margin: 32px auto 76px;
  }
}
