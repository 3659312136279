.appNotify {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--blue-500);
  height: 56px;
}

.appNotify__text {
  span {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  p {
    font-size: 12px;
  }
}

.appNotify__btn {
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  height: 31px;
  line-height: 1px;
}
