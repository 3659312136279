.phoneCodesSheet__span-select {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;

  span {
    color: var(--grey-600);
  }

  svg {
    margin-top: 3px;
    transition: 0.3s;

    path {
      fill: var(--grey-600);
    }
  }
}

.phoneCodes__hints {
  transform: translateX(-2px);
  width: calc(100% + 5px);
}

.phoneCodes__hints__item {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
