.label {
  border-radius: 10px;
  color: var(--white);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variation-settings: "wght" 460;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 24px;
  max-width: max-content;
  padding: 6px 16px;
  white-space: nowrap;
}

.label_small {
  border-radius: 6px;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 10px;
}

.label_stack {
  border-radius: 0px 6px 0px 0px;
  line-height: 14px;
  padding: 4px 4px 2px;

  &:last-child {
    border-radius: 0 6px;
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 960px) {
  .label_adaptive {
    border-radius: 6px;
    font-size: 12px;
    line-height: 12px;
    padding: 7px 10px;
  }
}
