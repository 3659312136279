.customServiceDrawer_bottom {
  .customDrawer__content-wrapper {
    height: auto !important;
    max-height: calc(100% - 56px);
    border-radius: 12px 12px 0px 0px;
    max-width: 960px;
    margin: 0 auto;
  }

  .customDrawer__content {
    border-radius: 12px 12px 0px 0px;
  }

  .customDrawer__content__main__body {
    padding: 0;
  }
}

/* ========== */
.customServiceDrawer_bottom__title {
  line-height: 16px;
  padding: 24px 0 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  margin: 0 32px;
  box-shadow: 0px 6px 6px -6px rgba(72, 91, 159, 0.05);
}

.customServiceDrawer_bottom__title__closeIcon {
  cursor: pointer;
  padding: 16px 16px 0px 0px;
  position: absolute;
  right: -32px;
  top: 0px;
}

/* ========== */
.customServiceDrawer_bottom__content {
  overflow: auto;
  padding: 0 32px;
}

.customServiceDrawer_bottom__content__item {
  align-items: center;
  border-top: 1px solid var(--grey-300);
  color: var(--grey-800);
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 11px 0 12px;
  transition: 0.3s;
  width: 100%;

  &[data-last-child="true"] {
    border-bottom: 1px solid var(--grey-300);
    padding-bottom: 10px;
  }

  svg[data-type="checkIcon"] path {
    fill: var(--blue-500);
  }

  &:disabled {
    cursor: default;
    color: var(--grey-600);
  }
}

.customServiceDrawer_bottom__content__item_disabled {
  color: var(--grey-600);
  cursor: default;
  pointer-events: none;

  svg[data-type="checkIcon"] path {
    fill: var(--blue-300);
  }
}

/* ========== */
.customServiceDrawer_bottom__content__controls {
  display: flex;
  margin: 24px 0;

  button {
    width: 100%;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

@media screen and (max-width: 600px) {
  .customServiceDrawer_bottom__title {
    margin: 0 18px;
  }

  .customServiceDrawer_bottom__title__closeIcon {
    right: -18px;
  }

  /* ========== */
  .customServiceDrawer_bottom__content {
    padding: 0 18px;

    .productSizesControls {
      border-top: 1px solid var(--grey-300);
      margin: 0;
      padding-bottom: 12px;
      padding-top: 23px;
    }
  }
}

@media screen and (max-width: 380px) {
  .customServiceDrawer_bottom__content__controls {
    flex-direction: column-reverse;

    button {
      &:not(:last-child) {
        margin-top: 8px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

@media screen and (max-height: 375px) {
  .customServiceDrawer_bottom {
    .customDrawer__content-wrapper {
      max-height: 100%;
      border-radius: 0;
    }

    .customDrawer__content {
      border-radius: 0;
    }
  }
}

.customServiceDrawer_side {
  .customDrawer__content-wrapper {
    width: 100% !important;

    .customDrawer__content__main__header,
    .customDrawer__content__main__footer,
    .customDrawer__content__main__body {
      border: none;
      padding: 0;
    }

    .customDrawer__content__main__header__title {
      line-height: 0;
    }
  }
}

/* ========== */
.customServiceDrawer_side__header {
  border-bottom: 1px solid var(--grey-300);
  color: var(--grey-700);
  padding: 20px 0 19px;
  position: relative;
  text-align: center;
}

.customServiceDrawer_side__header__title {
  color: var(--grey-700);
  text-transform: uppercase;
}

.customServiceDrawer_side__header__closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px 12px 0 0;
}

/* ========== */
.customServiceDrawer_side__body {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  height: 100%;
  overflow: auto;
}

/* ========== */
.customServiceDrawer_side__footer {
  border-top: 1px solid var(--grey-300);
  padding: 11px 12px 16px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .customServiceDrawer_side__body {
    padding: 24px 18px;
  }

  .customServiceDrawer_side__footer {
    border-top: none;
    box-shadow: 0px 1px 6px rgba(72, 91, 159, 0.05), 0px 6px 24px rgba(72, 91, 159, 0.16);
    padding: 6px 12px;
  }
}
