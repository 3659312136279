.clientWidget {
  align-items: center;
  animation: clientWidgetDisplay 2s forwards 1s;
  background-color: var(--white);
  border-radius: 64px;
  bottom: 26px;
  display: flex;
  filter: drop-shadow(0px 6px 24px rgba(143, 143, 143, 0.2));
  flex-direction: column;
  gap: 8px;
  height: 64px;
  justify-content: center;
  opacity: 0.8;
  position: fixed;
  right: -100px;
  transition: 0.4s;
  width: 64px;
  z-index: 5;

  @keyframes clientWidgetDisplay {
    to {
      right: 24px;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.clientWidget_opened {
  // height: 160px; // with telegram
  height: 112px;
}

/* ========== */
.clientWidget__icon {
  animation: opacity 1s forwards;
  cursor: pointer;
  opacity: 0;

  @keyframes opacity {
    to {
      opacity: 1;
    }
  }
}

/* ========== */
.clientWidget__popover__txt {
  color: var(--grey-700);
}

/* ========== */
.clientWidget__drawer .customDrawer__content-wrapper {
  max-width: 100%;
}

.clientWidget__drawer__content {
  display: grid;
  gap: 8px;
  padding: 12px 18px;
}

.clientWidget__drawer__content__control {
  justify-content: center;
}

.clientWidget__drawer__content__control__icon {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 960px) {
  .clientWidget {
    bottom: 71px;

    @keyframes clientWidgetDisplay {
      to {
        right: 12px;
      }
    }
  }
}
