@import "Style/style.scss";

:root {
  --beige-500: #f9f4f0;
  --beige-600: #ebdacc;
  /* ========== */
  --black: #1b1b1b;
  /* ========== */
  --blue-100-alfa: rgba(7, 61, 255, 0.2);
  --blue-200-alfa: rgba(7, 61, 255, 0.4);
  --blue-40: #f1f5ff;
  --blue-100: #cdd8ff;
  --blue-200: #9cb1ff;
  --blue-300: #6a8bff;
  --blue-400: #3964ff;
  --blue-500: #073dff;
  --blue-600: #002ed2;
  // --blue-700: hsl(226, 100%, 31%);
  // --blue-800: hsl(226, 100%, 21%);
  // --blue-900: hsl(226, 100%, 10%);
  /* ========== */
  --green-50: #ceeedd;
  --green-100: #d9ece4;
  --green-400: #33b770;
  --green-500: #0aa953;
  /* ========== */
  --grey-50: #f7f9fb;
  --grey-100: #eff2f6;
  --grey-200: #e5e8ed;
  --grey-300: #d9dde2;
  --grey-400: #cbd0d8;
  --grey-500: #b6bcc3;
  --grey-600: #a1a4a8;
  --grey-700: #696b6d;
  --grey-800: #535557;
  --grey-850: #525d73;
  --grey-900: #292b2c;
  /* ========== */
  --red-100: #fddfdf;
  --red-200: hsl(0, 88%, 83%);
  --red-400: #ee7373;
  --red-500: #eb5757;
  --red-500-alfa06: hsla(0, 79%, 63%, 0.06);
  /* ========== */
  --warning-50: #fee7cc;
  --warning-300: #fcb055;
  --warning-500: #fa8900;
  /* ========== */
  --white: #fff;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  outline: none;
}

html,
body {
  min-height: 100vh;
  height: auto;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  margin: 0;
  font-variant: tabular-nums;
  width: 100%;
}

main {
  display: block;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}

[hidden] {
  display: none;
}

#root {
  font-size: 14px;
  line-height: 1;
  color: var(--black);
  font-family: Inter, sans-serif;
  font-weight: 400;
  height: 100%;
  min-height: 100vh;
}

p,
ul,
ol,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: var(--black);
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  transition: 0.3s;
  background-color: transparent;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

button {
  background-color: var(--white);
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  transition: 0.3s;
}

input[type="checkbox"] {
  cursor: pointer;
}

label {
  cursor: pointer;
}

hr {
  border: none;
  min-height: 1px;
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

iframe {
  border: none;
}

.main__wrapper {
  min-height: 150vh;
  display: grid;
  gap: 0 16px;
  justify-content: center;
}

.app__wrapper {
  position: relative;
  height: 100%;
  grid-column: 1 / -1;
  grid-row: 2/3;
  width: 100%;

  &[data-is_header_shown="false"] {
    grid-row: 1/3;
  }
}

.custom-scroll {
  height: 100%;
}

.main__wrapper__header__attic__background {
  width: 100%;
  height: 40px;
  background-color: var(--black);
  position: absolute;
}

.app__wrapper__suspenseLoader {
  height: 100%;
  min-height: 150px;
}

.customDisabledContent {
  cursor: default;
  opacity: 0.7;
  pointer-events: none;
  transition: 0.3s;
}

/* Ynadex map */
ymaps[class*="image-with-content"] {
  // remove default map placemark
  background: none !important;
}

@media screen and (min-width: 601px) {
  .main__wrapper {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 180px 1fr 533px;
    margin: 0 32px;

    &[data-is_header_shown="false"] {
      grid-template-rows: 1fr 533px;
    }
  }
}

@media screen and (min-width: 961px) {
  .main__wrapper {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media screen and (min-width: 1280px) {
  .main__wrapper {
    grid-template-columns: repeat(10, 1fr);
  }
}

@media screen and (min-width: 1366px) {
  .main__wrapper {
    grid-template-columns: repeat(12, 1fr);
    margin: 0 56px;
  }
}

@media screen and (min-width: 1440px) {
  .main__wrapper {
    grid-template-columns: repeat(12, 96px);
    grid-template-rows: 188px 1fr 533px;
    margin: 0 auto;

    &[data-is_header_shown="false"] {
      grid-template-rows: 1fr 533px;
    }
  }
}

@media screen and (min-width: 1680px) {
  .main__wrapper {
    grid-template-columns: repeat(14, 96px);
  }
}

@media screen and (min-width: 1920px) {
  .main__wrapper {
    grid-template-columns: repeat(16, 96px);
  }
}

/* ========== */
@media screen and (max-width: 960px) {
  .main__wrapper__header__attic__background {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .main__wrapper {
    grid-template-rows: 56px 1fr 383px;

    &[data-is_header_shown="false"] {
      grid-template-rows: 1fr;
    }
  }
}

@media screen and (max-width: 600px) {
  .main__wrapper {
    gap: 0 6px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 56px 1fr;
    margin: 0 12px;
    padding-bottom: 62px;

    &[data-is_header_shown="false"] {
      grid-template-rows: 1fr;
    }
  }
}

@media screen and (max-width: 400px) {
  ymaps[class*="controls__control"] {
    // move default map zoom controls
    inset: 10px auto auto 10px !important;
  }
}
