.verificationCode {
  display: flex;
  justify-content: space-between;
  margin: 24px auto 0;
  width: 100%;
}

.verificationCode__item {
  aspect-ratio: 1 / 1.11;
  width: 100%;

  &:not(:last-child) {
    margin-right: 13px;
  }

  .customInput,
  .customInput__main {
    height: 100%;
  }

  .customInput__main__input {
    font-family: Inter, sans-serif;
    font-feature-settings: "ss01" on, "cv01" on;
    font-size: 29px;
    font-style: normal;
    font-variation-settings: "wght" 750, "slnt" 0;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 40px;
    padding: 14px 10px;
    text-align: center;
  }
}
