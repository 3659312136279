.mobileMenu__drawer {
  transform: translateX(0px) !important;

  .customDrawer__content-wrapper {
    max-width: 375px;
    width: 100% !important;
  }

  .customDrawer__content {
    overflow: hidden;
  }

  .customDrawer__content__main__body {
    padding: 0;
  }
}

.mobileMenu__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  a {
    display: block;
  }
}

/* ========== */
.mobileMenu__navigation {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 32px 0 32px 24px;
}

.mobileMenu__navigation__item {
  max-width: 50%;
  text-transform: uppercase;
  width: 100%;

  &:not(:first-child) {
    margin-top: 18px;
  }

  &[data-is-highlight="true"] {
    color: var(--red-500);
  }
}

.mobileMenu__navigation__item_selected {
  color: var(--blue-500) !important;
  cursor: default;
  pointer-events: none;
}

/* ========== */
.mobileMenu__city {
  align-items: center;
  border-top: 1px solid var(--grey-300);
  cursor: pointer;
  display: flex;
  margin-top: auto;
  padding: 23px 24px;

  span {
    margin-left: 12px;
    color: var(--grey-800);
  }
}

/* ========== */
.mobileMenu__contacts {
  border-top: 1px solid var(--grey-300);
  display: flex;
  padding: 24px 24px 20px;
}

.mobileMenu__contacts__info {
  margin-left: 12px;

  a {
    color: var(--grey-800);
  }

  span {
    color: var(--grey-600);
    display: block;
    margin-top: 8px;
  }
}
