.citySelectionInput {
  width: 100%;
  position: relative;

  .citySelectionInput__loader {
    height: 24px;
    width: 24px;

    .loader__content {
      height: 100%;
      width: 100%;
    }
  }
}
