.selectionIndicator,
.selectionIndicator_active {
  padding: 4px 10px;
  border-radius: 4px;
  color: var(--grey-800);
  border: 2px solid var(--grey-300);
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  background-color: var(--white);

  &:hover {
    border-color: var(--grey-500);
  }

  &:active {
    background-color: var(--grey-100);
  }

  &[data-allowed="true"] {
    border-color: var(--red-100);
    color: var(--red-500);

    &:hover {
      border-color: var(--red-500);
    }

    &:active {
      background-color: var(--white);
    }
  }
}

.selectionIndicator_active {
  background-color: var(--grey-800);
  border-color: var(--grey-800);
  color: var(--white);

  &:hover {
    border-color: var(--grey-800);
  }

  &[data-allowed="true"] {
    border-color: var(--red-500);
    color: var(--white);
    background-color: var(--red-500);

    &:active {
      background-color: var(--red-500);
    }
  }
}
