.header__wrapper {
  background-color: var(--white);
  grid-column: 1 / -1;
  grid-row-start: 1 / 2;
  position: relative;
  margin-bottom: auto;

  .mobileContent {
    display: none;
  }
}

.header__sub-wrapper {
  position: relative;
}

/* ========== */
.header__attic {
  background-color: var(--black);
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  position: relative;
  width: 100%;
}

.header__attic__text {
  color: var(--grey-700);
  display: inline-block;
}

.header__attic__item {
  align-items: center;
  display: flex;
  min-height: 24px;
}

.header__attic__item__citySelection {
  align-items: center;
  cursor: pointer;
  display: flex;

  span {
    color: var(--grey-300);
    margin-left: 6px;
  }

  svg path {
    fill: var(--grey-300);
  }
}

.header__attic__item__pickupLink {
  color: var(--grey-600);
  margin-left: 16px;

  &:hover {
    color: var(--grey-300);
  }
}

.header__attic__item__contacts {
  align-items: center;
  display: flex;

  svg path {
    fill: var(--grey-300);
  }

  a {
    margin-left: 12px;
    color: var(--grey-300);
  }
}

.header__attic__item__workTime {
  color: var(--grey-600);
  margin-left: 12px;
}

/* ========== */
.header__attic__item__langBtn {
  background-color: var(--black);
  color: var(--grey-300);
  letter-spacing: 0;
  margin-left: 20px;
  text-align: right;
  text-transform: uppercase;
}

/* ========== */
.header__top {
  align-items: center;
  border-bottom: 1px solid var(--grey-300);
  display: flex;
  justify-content: space-between;
  max-height: 80px;
  position: relative;
  padding: 16px 0 15px;
}

.header__top_shadow {
  border-bottom: none;
  box-shadow: 0px 12px 20px hsla(0, 0%, 0%, 0.1);
}

/* ========== */
.header__top__genderSelection {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, max-content);
}

.header__top__genderSelection__control {
  color: var(--grey-700);
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.2s;

  &:hover {
    color: var(--grey-900);
  }
}

.header__top__genderSelection__control_active {
  color: var(--black);
}

/* ========== */
.header__top__logo {
  height: 48px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: 0s;
  width: 180px;

  svg {
    height: 100%;
    width: 100%;
  }
}

/* ========== */
.header__top__icons {
  display: grid;
  gap: 20px;

  svg {
    cursor: pointer;
    margin: auto;
  }
}

.header__top__icons_left {
  grid-template-columns: repeat(2, max-content);
}

.header__top__icons_right {
  align-items: center;
  grid-template-columns: repeat(4, max-content);

  a {
    max-height: 28px;
  }
}

.header__top__icons_right__search {
  min-width: 307px;
}

.header__top__icons__notificationsWrapper {
  position: relative;

  .notificationCounter_cart {
    left: 13px;
    top: 5px;
  }

  svg path {
    transition: 0.3s;
  }

  &:hover {
    svg path {
      fill: var(--blue-500);
    }

    .header__top__icons__notificationsWrapper__favouritesIcon path {
      stroke: var(--blue-500);
      fill: var(--white);
    }
  }
}

.header__top__icons__notificationsWrapper__favouritesIcon,
.header__top__icons__notificationsWrapper__cartIcon,
.header__top__icons__userAvatar__icon {
  height: 28px;
  padding: 2px;
  scale: (1.05);
  width: 28px;
}

.header__top__icons__counter {
  left: 16px;
  top: 5px;
  z-index: 2;
}

/* ========== */
.header__top__icons__userAvatar {
  border-radius: 28px;
  cursor: pointer;
  height: 28px;
  position: relative;
  width: 28px;

  img {
    border-radius: 28px;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  svg {
    path {
      transition: 0.2s;
    }

    &:hover path {
      fill: var(--blue-500);
    }
  }
}

/* ========== */
.header__bottom_fixed {
  background: var(--white);
  box-shadow: 0px 1px 6px rgba(72, 91, 159, 0.04), 0px 6px 24px rgba(72, 91, 159, 0.12);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.header__bottom__menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: max-content;
  padding: 24px 0 20px;
  position: relative;
}

.header__bottom__menu__item {
  padding: 0 12px;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s;
  white-space: nowrap;

  &:hover {
    color: var(--blue-400);
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &[data-is-highlight="true"] {
    color: var(--red-500);
  }
}

.header__bottom__menu__item_selected {
  color: var(--blue-500) !important;
  cursor: default;
  pointer-events: none;
}

.header__bottom__menu__skeletonItem {
  margin: 0 12px;
  height: 16px;
  border-radius: 4px;

  &:nth-child(1) {
    width: 105px;
    margin-left: 0px;
  }

  &:nth-child(2) {
    width: 68px;
  }

  &:nth-child(3) {
    width: 65px;
  }

  &:nth-child(4) {
    width: 110px;
  }

  &:nth-child(5) {
    width: 75px;
  }

  &:nth-child(6) {
    width: 60px;
  }

  &:nth-child(7) {
    width: 75px;
  }

  &:nth-child(8) {
    width: 60px;
  }

  &:nth-child(9) {
    width: 105px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1280px) {
  .header__top__icons_right__search {
    min-width: 207px;
  }

  .header__bottom__menu__item {
    font-size: 14px;
    padding: 0 8px;
  }
}

@media screen and (max-width: 1080px) {
  .header__bottom__menu__item {
    font-size: 12px;
  }
}

@media screen and (max-width: 960px) {
  .header__wrapper {
    left: 0;
    position: sticky;
    top: 0;
    transform: translateX(-32px);
    width: calc(100% + 64px);
    z-index: 10;

    &[data-browser="Firefox"] {
      position: fixed !important;
      transform: translateX(0px) !important;
      width: 100% !important;
    }

    .mobileContent {
      display: block;
    }

    .desctopContent {
      display: none;
    }
  }

  .header__sub-wrapper {
    padding: 0;
  }

  .header__top {
    height: 56px;
    padding: 14px 32px;
  }

  .header__attic {
    display: none;
  }

  .header__top__logo {
    height: 36px;
    width: 144px;
  }

  .header__top__icons {
    display: grid !important;
    gap: 16px;
  }

  .header__top__icons_right {
    grid-template-columns: 1fr;
  }

  .header__top__genderSelection,
  .header__bottom {
    display: none;
  }

  .header__top__icons__favouritesWithMask circle {
    // active favourites page icon
    fill: var(--blue-600);
    stroke: var(--grey-600);
    transition: 0.3s;
  }

  .header__top__icons__notificationWithMask {
    // notification counter
    background-color: var(--blue-600) !important;
    color: var(--grey-500) !important;
    transition: 0.3s;
  }
}

@media screen and (max-width: 600px) {
  .header__wrapper {
    transform: translateX(-12px);
    width: calc(100% + 24px);
  }

  .header__top {
    padding: 0px 12px;
  }
}

@media screen and (max-width: 319px) {
  .header__top__logo {
    width: 112px;
  }

  .header__top__icons {
    gap: 8px;
  }
}
