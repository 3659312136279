.snackbarNotification {
  align-items: center;
  background-color: var(--white);
  border-radius: 18px;
  bottom: -100px;
  box-shadow: 0px 1px 6px rgba(72, 91, 159, 0.05), 0px 6px 24px rgba(72, 91, 159, 0.16);
  display: flex;
  left: 50%;
  max-width: 90%;
  min-width: 390px;
  opacity: 0;
  padding: 16px 16px 16px 18px;
  position: fixed;
  transform: translateX(-50%);
  transition: 0.5s;
  width: max-content;
  z-index: 1100;
}

.snackbarNotification_visible {
  animation: isVisible 4s alternate;

  @keyframes isVisible {
    0% {
      bottom: -100px;
      opacity: 0.5;
    }

    20%,
    80% {
      bottom: 60px;
      opacity: 1;
    }

    100% {
      bottom: -100px;
      opacity: 0.5;
    }
  }
}

.snackbarNotification__icon {
  align-items: center;
  background-color: var(--grey-100);
  border-radius: 10px;
  display: flex;
  height: 44px;
  justify-content: center;
  min-width: 44px;
  width: 44px;
}

.snackbarNotification__msg {
  font-size: 16px;
  margin-left: 12px;
  word-break: break-word;
}

@media screen and (max-width: 600px) {
  .snackbarNotification {
    min-width: 80%;
    width: auto;
  }

  .snackbarNotification__msg {
    font-size: 14px;
  }
}
