.optionalCarousel {
  display: flex;
  flex-direction: column;
}

.optionalCarousel__title,
.optionalCarousel__title_skeleton {
  margin: 32px 0;
  text-align: center;
  text-transform: uppercase;
}

.optionalCarousel__title_skeleton {
  align-self: center;
  border-radius: 6px;
  height: 40px;
  width: 200px;
}

.optionalCarousel__slider {
  margin-top: 24px;
  position: relative;
}

@media screen and (max-width: 960px) {
  .optionalCarousel__title,
  .optionalCarousel__title_skeleton {
    margin: 24px 0;
  }

  .optionalCarousel__slider {
    margin-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .optionalCarousel__title_skeleton {
    height: 24px;
  }
}
