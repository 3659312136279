.phoneInput {
  height: 100%;
  line-height: 16px;
  position: relative;
  width: 100%;

  input {
    border: none;
    height: 100%;
    padding: 0;
    width: 100%;

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 50px var(--white);
      -webkit-text-fill-color: var(--black);
    }

    &:disabled {
      color: var(--grey-600) !important;
      background-color: var(--white);
    }
  }

  p {
    position: absolute;
    top: 15px;
    left: 21px;
    line-height: 16px !important;
    font-size: 14px !important;
    margin: 0 !important;

    .phoneInput__mask__item::after {
      display: block;
      content: "";
      background-color: black;
      height: 1px;
      position: absolute;
      bottom: -17px;
      width: 9px;
    }

    .span_1::after {
      left: -17px;
    }

    .span_2::after {
      left: -10px;
    }

    .span_3::after {
      left: -3px;
    }

    .span_4::after {
      left: 10px;
    }

    .span_5::after {
      left: 17px;
    }

    .span_6::after {
      left: 24px;
    }

    .span_7::after {
      left: 37px;
    }

    .span_8::after {
      left: 44px;
    }

    .span_9::after {
      left: 57px;
    }

    .span_10::after {
      left: 64px;
    }
  }
}
