.gender-selection {
  border-bottom: 1px solid var(--grey-300);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px 0;
  text-align: center;
  text-transform: uppercase;
}

.gender-selection__item {
  color: var(--grey-600);
  cursor: pointer;
  padding: 8px 0;
  text-align: center;
  transition: 0.3s;

  &:hover {
    color: var(--grey-700);
  }

  &:nth-child(2) {
    border: 1px solid var(--grey-300);
    border-style: none solid;
  }
}

.gender-selection__item_active {
  color: var(--black) !important;
}

.gender-selection_mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .gender-selection_mobile {
    display: grid;
    left: -32px;
    position: relative;
    width: calc(100% + 64px);
  }
}

@media screen and (max-width: 600px) {
  .gender-selection_mobile {
    left: -12px;
    width: calc(100% + 24px);
  }
}
