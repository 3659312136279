.promoAction__modal {
  max-width: 696px !important;
  width: 100%;

  .customModal__content__body {
    margin: 0;
  }
}

.promoAction__modal__content {
  border-radius: 12px;
  position: relative;
  width: 100%;
}

.promoAction__modal__content__banner {
  aspect-ratio: 8 / 5;
  border-radius: 12px;
  display: block;
  width: 100%;
}

/* ========== */
.promoAction__closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;
  padding: 12px 12px 0 0;
}

/* ========== */
.promoAction__drawer .customServiceDrawer_bottom__content {
  padding: 0 !important;
}

.promoAction__drawer__content {
  padding: 0;
  border-radius: 12px 12px 0 0;
}

.promoAction__drawer__content__banner {
  aspect-ratio: 100 / 93;
  display: block;
  width: 100%;
}

.promoAction__drawer__content__info {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 24px 16px !important;

  .firstOrderDiscountContent__authBtn {
    justify-content: center;
    margin-top: 24px;
    position: static;
    width: 100%;
  }
}

/* ========== */
.promoAction__actionControl-wrapper {
  bottom: 32px;
  left: 135px;
  position: absolute;
  width: max-content;
}

.promoAction__actionControl {
  justify-content: center;
  width: 100%;
}

/* ========== */
.promoAction__ui_hidden {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 600px) {
  .promoAction__actionControl-wrapper {
    padding: 16px;
    position: static;
    width: 100%;
  }
}
