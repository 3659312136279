/* ========== */
.locationSelect__links {
  margin-top: 24px;
}

.locationSelect__links__item {
  padding: 12px 8px 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-800);
  cursor: pointer;

  svg path {
    fill: var(--blue-500);
  }
}

/* ========== */
.locationSelect__defaultCitiesLoader {
  margin-top: 24px;
  display: flex;
  height: 50px;
}

@media screen and (max-width: 600px) {
  .locationSelect {
    padding: 10px !important;

    .customModal__contentWrapper {
      border-radius: 8px;
      padding: 20px 16px 16px;
    }
  }
}
