.catalogsGrid {
  display: grid;
  gap: 40px 24px;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 960px) {
  .catalogsGrid {
    gap: 24px 16px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .catalogsGrid {
    gap: 24px 5px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 320px) {
  .catalogsGrid {
    grid-template-columns: 1fr;
  }
}
