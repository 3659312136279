.adaptivePopup__drawer {
  color: var(--black);

  .customServiceDrawer_bottom__content {
    padding-bottom: 24px;
  }

  .customServiceDrawer_bottom__title {
    box-shadow: none;
  }
}

.adaptivePopup__modal {
  color: var(--black);
}
