.popularCategories__title {
  text-align: center;
  text-transform: uppercase;
}

.popularCategories__content {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 32px;
  position: relative;
}

.popularCategories__content_skeletonLayout {
  gap: 16px;
  width: 100% !important;
}

.popularCategories__item {
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.popularCategories__item_skeleton {
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  min-height: 67px;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .popularCategories__content {
    gap: 8px;
  }
}

@media screen and (max-width: 768px) {
  .popularCategories__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .popularCategories__content {
    margin-top: 24px;
  }
}
