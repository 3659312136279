.submitBtn,
.submitBtn_withLoader {
  justify-content: center;
  width: 100%;
}

.submitBtn_withLoader {
  align-items: center;
  position: relative;
  white-space: nowrap;

  .loader__wrapper {
    right: -16px;
    width: 0px;
  }
}

.submitBtn_modalMargin {
  margin-top: 24px;
}

.submitBtn__text {
  position: relative;
}

.submitBtn__text__loader {
  height: 0 !important;
  position: absolute !important;
  right: -16px;
  top: 50%;
  width: 0;
}
