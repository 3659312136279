.hiddenEl {
  max-height: 0;
  max-width: 0;
  overflow: hidden;

  * {
    max-height: 0;
    max-width: 0;
  }
}
