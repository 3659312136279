/* Chrome, Safari, Edge, Opera */
input.withoutArrows::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input.withoutArrows::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input.withoutArrows {
  -moz-appearance: textfield;
}
