.notFound404 {
  padding: 40px 0 120px;
}

.notFound404__sorry {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 566px;
}

.notFound404__sorry__title {
  margin-top: 36px;
  text-align: center;
}

.notFound404__sorry__subTitle {
  color: var(--grey-800);
  margin-top: 16px;
  text-align: center;
}

.notFound404__sorry__homeLink {
  margin-top: 24px;
}

/* ========== */
.notFound404__popularCategories,
.notFound404__popularBrands {
  margin-top: 100px;
}

/* ========== */
.notFound404__popularBrands .popularBrands__grid {
  margin-top: 56px;
}

@media screen and (max-width: 960px) {
  .notFound404 {
    padding-bottom: 80px;
  }

  /* ========== */
  .notFound404__popularCategories {
    margin-top: 73px;
  }
}

@media screen and (max-width: 600px) {
  .notFound404 {
    padding-bottom: 60px;
  }

  /* ========== */
  .notFound404__popularCategories,
  .notFound404__popularBrands {
    margin-top: 64px;
  }

  /* ========== */
  .notFound404__popularBrands .popularBrands__grid {
    margin-top: 40px;
  }
}
