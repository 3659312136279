.carouselControls-arrow {
  position: absolute;
  top: 50%;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 3px 10px hsla(0, 0%, 0%, 0.2);
  z-index: 2;

  svg {
    transition: 0.1s;
    margin: 0 auto;
  }

  &:first-child {
    left: 0;
    transform: translate(-24px, -115%);

    &:active:not(:disabled) svg {
      transform: scale(1.1);
    }
  }

  &:last-child {
    right: 0;
    transform: translate(8px, -115%);

    svg {
      transform: rotate(180deg);
    }

    &:active:not(:disabled) svg {
      transform: rotate(180deg) scale(1.1);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@media screen and (max-width: 830px) {
  .carouselControls-arrow {
    width: 38px;
    height: 38px;
    font-size: 16px;

    &:active {
      font-size: 14px;
    }

    &:first-child {
      transform: translate(-18px, -175%);
    }

    &:last-child {
      transform: translate(8px, -175%);
    }
  }
}

@media screen and (max-width: 600px) {
  .carouselControls-arrow {
    display: none;
  }
}
