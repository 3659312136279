.switchPanel {
  background-color: var(--blue-400);
  border-radius: 12px;
  display: flex;
  min-height: 44px;
  padding: 4px;
  gap: 2px;
}

.switchPanel__item {
  background-color: inherit;
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  justify-content: center;
  min-width: 170px;
  padding: 6px 16px;
  text-align: center;
  transition: 0.3s;
  white-space: nowrap;
}

.switchPanel__item_active {
  background-color: var(--white);
  color: var(--black);
  cursor: default;
  pointer-events: none;

  &:hover {
    color: var(--black);
  }
}

@media screen and (max-width: 600px) {
  .switchPanel__item {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .switchPanel__item {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: inherit;
    white-space: inherit;
  }
}
