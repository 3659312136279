.siteFooter {
  grid-row: 3 / 4;
  grid-column: 1 / -1;
  margin-top: auto;
}

/* ========== */
.siteFooter__desctop__contacts {
  align-items: center;
  border-bottom: 1px solid var(--grey-300);
  border-top: 1px solid var(--grey-300);
  display: flex;
  justify-content: space-between;
  padding: 37px 48px;
}

.siteFooter__desctop__contacts__item_questions {
  max-width: 152px;
  width: 100%;
}

/* ========== */
.siteFooter__desctop__links {
  padding: 40px 0;
  display: flex;
}

.siteFooter__desctop__links__devider {
  height: auto;
  min-width: 1px;
  background-color: var(--grey-300);
  margin: 0 48px;
}

.siteFooter__desctop__links__item {
  width: 100%;

  &:first-child {
    padding-left: 48px;
  }

  .typography_tec_15_menu {
    text-transform: uppercase;
  }
}

.siteFooter__desctop__links__item_subscription {
  min-width: 330px;
}

.siteFooter__desctop__links__item__list {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 16px 32px;

  .typography_tec_16_default {
    color: var(--grey-800);
  }
}

.siteFooter__desctop__links__item__list_social {
  grid-template-columns: 1fr;
}

.siteFooter__desctop__links__item__list_brands {
  display: flex;
  flex-flow: row wrap !important;
  max-width: 500px;
  gap: 0 !important;

  .typography_tec_16_default {
    margin: 0 8px 16px 0 !important;
  }
}

/* ========== */
.siteFooter__desctop__policy {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--grey-300);
  padding: 33px 48px 51px;

  a {
    margin-left: 20px;
    color: var(--grey-700);
  }
}

.siteFooter__desctop__policy__address {
  color: var(--grey-700);
}

/* MOBILE */
.siteFooter__mobile {
  display: none;
}

.siteFooter__mobile__main {
  border-top: 1px solid var(--grey-300);
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 31px 32px 32px;
  transform: translateX(-32px);
  width: calc(100% + 64px);
}

.siteFooter__mobile__main__contacts {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.siteFooter__mobile__main__subscription {
  display: flex;
  flex-direction: column;
  max-width: 370px;
  row-gap: 24px;

  .typography_h2 {
    text-align: center;
  }
}

/* ========== */
.siteFooter__mobile__links__collapse__panel {
  // headers
  border-top: 1px solid var(--grey-300);

  .customCollapse__panel__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 19px 8px 19px 0;
    width: 100%;
  }

  .typography_tec_16_default {
    color: var(--grey-800);
  }

  // lists
  .customCollapse__panel__content {
    padding: 8px 0 32px 32px;
  }
}

.siteFooter__mobile__links__collapse__expandIcon {
  position: relative;
  transition: 0.3s;

  path {
    stroke: var(--grey-600);
  }
}

.siteFooter__mobile__links__collapse__panel__content {
  display: grid;
  gap: 16px;

  .typography_tec_13_medium {
    color: var(--grey-800);
  }
}

/* ========== */
.siteFooter__mobile__policy {
  border-top: 1px solid var(--grey-300);
  padding: 24px 0 28px;
  display: flex;
  justify-content: space-between;
}

.siteFooter__mobile__policy__info {
  max-width: 358px;
  width: 100%;

  a {
    display: block;
    margin-top: 16px;
  }

  .typography_tec_13_medium {
    color: var(--grey-700);
  }
}

.siteFooter__mobile__policy__mobileApps {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 133px);
  margin-left: 20px;
  max-height: 40px;

  img {
    aspect-ratio: 133 / 40;
    height: 100%;
    max-width: 133px;
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .siteFooter__desctop__links__devider {
    margin: 0 32px;
  }

  .siteFooter__desctop__links__item__list {
    gap: 16px 24px;
  }
}

@media screen and (max-width: 1200px) {
  .siteFooter__desctop__contacts {
    padding: 37px 0;
  }

  .siteFooter__desctop__links__item:first-child {
    padding-left: 0px;
  }

  .siteFooter__desctop__links__item__list {
    display: flex;
    flex-direction: column;
    gap: 0;

    .typography_tec_16_default:not(:first-child) {
      margin-top: 16px;
    }
  }

  .siteFooter__desctop__links__item__list_reordered {
    .typography_tec_16_default:not(:first-child) {
      margin-top: 16px;
    }

    .typography_tec_16_default:nth-child(1),
    .typography_tec_13_medium:nth-child(1) {
      order: 1;
    }

    .typography_tec_16_default:nth-child(3),
    .typography_tec_13_medium:nth-child(3) {
      order: 2;
    }

    .typography_tec_16_default:nth-child(5),
    .typography_tec_13_medium:nth-child(5) {
      order: 3;
    }

    .typography_tec_16_default:nth-child(7),
    .typography_tec_13_medium:nth-child(7),
    .typography_tec_16_default:nth-child(9),
    .typography_tec_13_medium:nth-child(9) {
      order: 4;
    }

    .typography_tec_16_default:nth-child(2),
    .typography_tec_13_medium:nth-child(2) {
      order: 5;
    }

    .typography_tec_16_default:nth-child(4),
    .typography_tec_13_medium:nth-child(4) {
      order: 6;
    }

    .typography_tec_16_default:nth-child(6),
    .typography_tec_13_medium:nth-child(6) {
      order: 7;
    }

    .typography_tec_16_default:nth-child(8),
    .typography_tec_13_medium:nth-child(8) {
      order: 8;
    }

    .typography_tec_16_default:nth-child(n + 10),
    .typography_tec_13_medium:nth-child(n + 10) {
      order: 9;
    }
  }

  .siteFooter__desctop__policy {
    padding-left: 0;
  }

  .siteFooter__desctop__policy__address {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 960px) {
  .siteFooter {
    padding-bottom: 60px;
  }

  .siteFooter__desctop {
    display: none;
  }

  .siteFooter__mobile {
    display: block;
  }
}

@media screen and (max-width: 810px) {
  .siteFooter__mobile__main {
    flex-direction: column;
    align-items: center;
  }

  .siteFooter__mobile__main__contacts,
  .siteFooter__mobile__main__subscription {
    align-items: center;
  }

  .siteFooter__mobile__main__subscription .newsSubscription {
    align-items: center;

    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .siteFooter {
    padding-bottom: 0px;
  }

  .siteFooter__mobile__main {
    padding: 40px 12px 24px;
    transform: translateX(-12px);
    width: calc(100% + 24px);
  }

  .siteFooter__mobile__policy {
    flex-direction: column;
    justify-content: center;
  }

  .siteFooter__mobile__policy__info {
    order: 2;
    margin-top: 24px;
    max-width: 100%;

    p,
    a {
      text-align: center;
    }

    a {
      margin-top: 12px;
    }
  }

  .siteFooter__mobile__policy__mobileApps {
    order: 1;
    margin-left: 0;
    display: flex;
    justify-content: center;
    gap: 0;

    a:last-child {
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: 375px) {
  .siteFooter__mobile__main__contacts .storeContactInfo {
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .siteFooter__mobile__policy__mobileApps {
    flex-direction: column;
    align-items: center;
    margin: 16px 0;

    a:last-child {
      margin-left: 0;
      margin-top: 8px;
    }
  }
}
