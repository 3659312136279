.catalogProductCardSkeleton__preview {
  aspect-ratio: 2 / 3;
  border-radius: 6px;
  min-height: 188px;
  width: 100%;
}

.catalogProductCardSkeleton__title {
  border-radius: 3px;
  height: 15px;
  margin-top: 12px;
  max-width: 50%;
}

.catalogProductCardSkeleton__price {
  display: grid;
  gap: 10px;
  grid-template-columns: 30% 70%;
  margin-top: 8px;
  max-width: 90%;
}

.catalogProductCardSkeleton__price__item {
  border-radius: 3px;
  height: 16px;
}
