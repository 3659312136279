.newsSubscription {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.newsSubscription__input {
  max-width: 360px;

  input {
    margin: 0 !important;
    padding-left: 12px;
  }

  .customInput__main__prefix {
    margin: 0;
    order: 2;
    padding: 0px 10px 0px 0px;
  }

  .customInput__main__suffix {
    margin: 0;
    order: 3;
    padding: 0;
  }
}

.newsSubscription__input__subscribeBtn {
  align-items: center;
  border-radius: 0 12px 12px 0;
  display: flex;
  height: calc(100% + 4px);
  transform: translateX(2px);
  width: 56px;

  svg {
    transform: rotate(180deg);
  }
}

.newsSubscription__genders {
  column-gap: 16px;
  display: flex;
}

.newsSubscription__convention {
  color: var(--grey-800);

  a {
    color: var(--black);
    text-decoration: underline;
  }
}
